import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import rating from "./slices/ratingSlice";
import report from "./slices/reportSlice";
import department from "./slices/departmentSlice";
import category from "./slices/categorySlice";
import employee from "./slices/employeeSlice";
import employeeTransfer from "./slices/employeeTransferSlice";
import userPermit from "./slices/userPermissionSlice";
import role from "./slices/rolePermitSlice";
import setting from "./slices/settingSlice";
import posts from "./slices/postsSlice";
import hospitalManagement from "./slices/hospitalManagementSlice";
import doctorManagement from "./slices/doctorManagementSlice";
import appointment from "./slices/appointmentSlice";
import notification from "./slices/notificationSlice";
import ambulance from "./slices/ambulanceSlice";
import comment from "./slices/commentSlice";
import bhytCategory from "./slices/bhxhCategorySlice";
import hrRecord from "./slices/hrRecordSlice";
import bangCong from "./slices/bangCongSlice";
import dichvutainhacategory from "./slices/dichVuTaiNhaCategorySlice";
import dichvutainha from "./slices/dichVuTaiNhaSlice";
import khachhang from "./slices/khachhangSlice";
import tuyenDung from "./slices/tuyenDungSlice";
import quanLyDuAn from "./slices/quanLyDuAnSlice";
import congvan from "./slices/quanLyCongVanSlice";
import sangKienYTuong from "./slices/sangKienYTuongSlice";
import qlCong from "./slices/qlCongSlice";
import qlNghi from "./slices/qlNghiSlice";
import ketquaCdha from "./slices/cdhaSlice";
//Contract Import
import contractTypes from "./slices/employmentContract/contractTypesSlice";
import contractSettings from "./slices/employmentContract/contractSettingsSlice";
import hrmContract from "./slices/employmentContract/hrmContractSlice";
import contract from "./slices/employmentContract/contractSlice";

//Training Import
import trainingMethods from "./slices/training/trainingMethodSlice";
import trainingCenters from "./slices/training/trainingCenterSlice";
import trainingExpenses from "./slices/training/trainingExpenseSlice";
import trainingPrograms from "./slices/training/trainingProgramSlice";
import trainingEmployees from "./slices/training/trainingEmployeeSlice";

//Signature Import
import signatureProcess from "./slices/signatureProcessSlice";
//Overtime
import overTimes from "./slices/overtime/overTimeSlice";
import partners from "./slices/partner/partnerSlice";
//MEAL ALOWWANCE
import mealAllowances from "./slices/mealAllowance/mealAllowanceSlice";
// Bang luong
import thietlapluong from "./slices/salary/thietLapLuongSlide";
import quanlyluong from "./slices/salary/quanLyLuongSlice";
import bangPhuCapGiamTru from "./slices/salary/quanLyPhuCapGiamTruSlice";
// Common
import attachment from "./slices/common/attachmentSlide";
import erpComment from "./slices/common/erpCommentSlice";
import regulationDocumentSetting from "./slices/regulation/regulationDocumentSettingSlice";

import regulationManage from "./slices/regulation/regulationManageSlice";
import documentManage from "./slices/regulation/documentManageSlice";
import hoSoManage from "./slices/regulation/hoSoManageSlice";
import ghiNhanYeuCau from "./slices/ghiNhanYeuCau/ghiNhanYeuCauSlice";
import erpNotification from "./slices/common/erpNotificationSlice";

import ksk from "./slices/khamSucKhoeDoanSlice";
import crmExport from "./slices/crm/crmExportSlice";
//sale

import banHangSale from "./slices/sale/banhangSlice";
import baoCaoSale from "./slices/sale/baocaoSlice";
import danhMucSale from "./slices/sale/danhmucSlice";
import nhapKhoSale from "./slices/sale/nhapkhoSlice";
import settingSale from "./slices/sale/settingSlice";
import thuChiSale from "./slices/sale/thuchiSlice";
import traCuuSale from "./slices/sale/tracuuSlice";
import traHangSale from "./slices/sale/trahangSlice";
import uploadSale from "./slices/sale/uploadFileSlice";
import xuatKhoSale from "./slices/sale/xuatkhoSlice";
import nhapXuatSale from "./slices/sale/nhapxuatSlice";
import thuTienSale from "./slices/sale/thuchiSlice";
import deXuatMuaSam from "./slices/sale/dexuatmuasamSlice";
import tonghopdeXuatMuaSam from "./slices/sale/tonghopdexuatmuasamSlice";
import salechangehistory from "./slices/sale/salechangehistorySlice";
import kehoachmuahang from "./slices/sale/kehoachmuahangSlice";

//Thi?t l?p kh�c

import chucVuCategory from "./slices/thietLapKhac/chucVuCategorySlice";
import chucDanhCategory from "./slices/thietLapKhac/chucDanhCategorySlice";
import chuyenMonCategory from "./slices/thietLapKhac/chuyenMonCategorySlice";
import hocVanCategory from "./slices/thietLapKhac/hocVanCategorySlice";

// Marketing campaign
import marketingCampaign from "./slices/crm/marketingCampaignSlide";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    rating,
    report,
    department,
    category,
    employee,
    userPermit,
    role,
    posts,
    setting,
    ketquaCdha,
    hospitalManagement,
    doctorManagement,
    appointment,
    notification,
    ambulance,
    comment,
    bhytCategory,
    hrRecord,
    bangCong,
    dichvutainhacategory,
    dichvutainha,
    khachhang,
    sangKienYTuong,
    contractTypes,
    contractSettings,
    hrmContract,
    contract,
    tuyenDung,
    quanLyDuAn,
    congvan,
    trainingMethods,
    trainingCenters,
    trainingExpenses,
    trainingPrograms,
    trainingEmployees,
    qlCong,
    overTimes,
    signatureProcess,
    mealAllowances,
    // Bang luong
    thietlapluong,
    quanlyluong,
    bangPhuCapGiamTru,
    // Common
    attachment,
    qlNghi,
    erpNotification,
    regulationDocumentSetting,
    regulationManage,
    documentManage,
    hoSoManage,
    ksk,
    ghiNhanYeuCau,
    banHangSale,
    partners,
    baoCaoSale,
    danhMucSale,
    nhapKhoSale,
    settingSale,
    thuChiSale,
    traCuuSale,
    traHangSale,
    uploadSale,
    xuatKhoSale,
    nhapXuatSale,
    thuTienSale,
    deXuatMuaSam,
    tonghopdeXuatMuaSam,
    employeeTransfer,
    salechangehistory,
    kehoachmuahang,
    chucVuCategory,
    chucDanhCategory,
    chuyenMonCategory,
    hocVanCategory,
    marketingCampaign,
    crmExport,
    erpComment,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
