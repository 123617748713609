import axiosInstance from "auth/FetchInterceptor";
import { MARKETING_CAMPAIGN_API } from "constants/ApiConstant";
const MarketingCampaignService = {};

MarketingCampaignService.searchCallHistory = function (data) {
    return axiosInstance.post(`${MARKETING_CAMPAIGN_API}/search-call`, data, {
        baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    });
};

MarketingCampaignService.searchCampaign = function (data) {
    return axiosInstance.post(`${MARKETING_CAMPAIGN_API}/campaign-search`, data, {
        baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    });
};

MarketingCampaignService.getCampaignById = function (id) {
    return axiosInstance.get(`${MARKETING_CAMPAIGN_API}/getById?id=${id}`, {
        baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    });
};
MarketingCampaignService.addCampaign = function (data) {
    return axiosInstance.post(`${MARKETING_CAMPAIGN_API}/campaign-add`, data, {
        baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    });
};
MarketingCampaignService.updateCampaign = function (data) {
    return axiosInstance.put(`${MARKETING_CAMPAIGN_API}/campaign-update`, data, {
        baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    });
};
MarketingCampaignService.deleteCampain = function (id) {
    return axiosInstance.delete(`${MARKETING_CAMPAIGN_API}/campaign-delete/${id}`, {
        baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    });
};

MarketingCampaignService.copyCampain = function (id) {
    return axiosInstance.post(`${MARKETING_CAMPAIGN_API}/campaign-copy/${id}`, null, {
        baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    });
};

MarketingCampaignService.searchCallHistory = function (data) {
    return axiosInstance.post(`${MARKETING_CAMPAIGN_API}/search-call`, data, {
        baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    });
};
MarketingCampaignService.deleteOutboundCall = function (data) {
    return axiosInstance.delete(`${MARKETING_CAMPAIGN_API}/cancel-call`, data, {
        baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    });
};

MarketingCampaignService.reCall = function (id) {
    return axiosInstance.get(`${MARKETING_CAMPAIGN_API}/re-call/${id}`, {
        baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    });
};

MarketingCampaignService.reCalls = function (data) {
    return axiosInstance.post(`${MARKETING_CAMPAIGN_API}/re-calls`, data, {
        baseURL: process.env.REACT_APP_CRM_ENDPOINT_URL,
    });
};
export default MarketingCampaignService;
