import React from "react";
import {
  AUTH_PREFIX_PATH,
  APP_PREFIX_PATH,
  CDHA_PREFIX_PATH,
} from "configs/AppConfig";

export const publicShareRoute = [
  {
    key: "rating.submit",
    path: `/customer/rating-form`,
    component: React.lazy(() =>
      import("views/app-views/crm/ratings/FormRatingSubmit")
    ),
  },
  {
    key: "esign",
    path: `/customer/esign`,
    component: React.lazy(() => import("views/app-views/crm/esign/index")),
  },
  {
    key: "idea-initiative",
    path: `/customer/idea-initiative/form`,
    component: React.lazy(() =>
      import("views/app-views/hrm/IdeaInitiative/formData")
    ),
  },
  {
    key: "idea-initiative-success",
    path: `/customer/idea-initiative/success`,
    component: React.lazy(() =>
      import("views/app-views/hrm/IdeaInitiative/success")
    ),
  },
  {
    key: "ghi-nhan-yeu-cau",
    path: `/customer/ghi-nhan-yeu-cau`,
    component: React.lazy(() =>
      import("views/app-views/hrm/ghiNhanYeuCau/formData")
    ),
  },
  {
    key: "so-tay-doanh-nghiep",
    path: `${APP_PREFIX_PATH}/hrm/noi-bo/so-tay-doanh-nghiep`,
    component: React.lazy(() => import("views/app-views/hrm/noiBo/soTayDonVi")),
  },
  {
    key: "sinh-nhat-sap-toi",
    path: `${APP_PREFIX_PATH}/hrm/noi-bo/sinh-nhat-sap-toi`,
    component: React.lazy(() => import("views/app-views/hrm/noiBo/danhSachSinhNhat")),
  },
  {
    key: "ghi-nhan-yeu-cau-chi-tiet",
    path: `/customer/ghi-nhan-yeu-cau/detail`,
    component: React.lazy(() =>
      import("views/app-views/hrm/ghiNhanYeuCau/formData")
    ),
  },
  {
    key: "ghi-nhan-yeu-cau-success",
    path: `/customer/ghi-nhan-yeu-cau/success`,
    component: React.lazy(() =>
      import("views/app-views/hrm/ghiNhanYeuCau/success")
    ),
  },
  {
    key: "dang-ky-dich-vu-tai-nha",
    path: `/customer/dang-ky-dich-vu-tai-nha`,
    component: React.lazy(() =>
      import("views/app-views/crm/ghiNhanDichVuTaiNha/formData")
    ),
  },
  {
    key: "dang-ky-dich-vu-tai-nha-success",
    path: `/customer/dang-ky-dich-vu-tai-nha/success`,
    component: React.lazy(() =>
      import("views/app-views/crm/ghiNhanDichVuTaiNha/success")
    ),
  },
];
export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register-1",
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-1")
    ),
  },
  {
    key: "register-2",
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-2")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/reset-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/reset-password")
    ),
  },
  {
    key: "error-page-1",
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  {
    key: "error-page-2",
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-2")),
  },
  {
    key: "crm-cdha",
    path: `${CDHA_PREFIX_PATH}/cdha`,
    component: React.lazy(() => import("views/cdha-views")),
  },
  {
    key: "crm-xetnghiem",
    path: `${CDHA_PREFIX_PATH}/tracuu_cls`,
    component: React.lazy(() => import("views/xetnghiem-views/index")),
  },
  {
    key: "crm-xetnghiem-view",
    path: `${CDHA_PREFIX_PATH}/tracuu_cls/ketquaxetnghiem`,
    component: React.lazy(() =>
      import("views/xetnghiem-views/ketquaxetnghiem")
    ),
  },
  {
    key: "internal-documentviewer",
    path: `/documentviewer`,
    component: React.lazy(() =>
      import("views/app-views/common/documentViewer")
    ),
  },
];

export const protectedRoutes = [
  // dashboard

  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboard")),
  },
  {
    key: "hrm",
    path: `${APP_PREFIX_PATH}/hrm`,
    component: React.lazy(() => import("views/app-views/hrm/dashboard")),
  },
  {
    key: "crm",
    path: `${APP_PREFIX_PATH}/crm`,
    component: React.lazy(() => import("views/app-views/crm/dashboard")),
  },
  {
    key: "project",
    path: `${APP_PREFIX_PATH}/project`,
    component: React.lazy(() => import("views/app-views/project/dashboard")),
  },
  {
    key: "common",
    path: `${APP_PREFIX_PATH}/common`,
    component: React.lazy(() => import("views/app-views/common/dashboard")),
  },
  {
    key: "signature-report",
    path: `${APP_PREFIX_PATH}/common/tong-hop-ky`,
    component: React.lazy(() =>
      import("views/app-views/common/SignReport/SignReport")
    ),
  },
  {
    key: "notifications-report",
    path: `${APP_PREFIX_PATH}/common/tong-hop-thong-bao`,
    component: React.lazy(() =>
      import("views/app-views/common/NotiReport/NotiReport")
    ),
  },
  {
    key: "common-page-detail",
    path: `${APP_PREFIX_PATH}/common/detail-page`,
    component: React.lazy(() =>
      import("views/app-views/common/pageDetail/index")
    ),
  },
  // end dashboard
  {
    key: "crm.report.chatluongdichvu",
    path: `${APP_PREFIX_PATH}/crm/bao-cao/chat-luong-dich-vu`,
    component: React.lazy(() => import("views/app-views/crm/dashboards")),
  },
  {
    key: "crm.report.hoatdongkcb",
    path: `${APP_PREFIX_PATH}/crm/bao-cao/hoat-dong-kcb`,
    component: React.lazy(() =>
      import("views/app-views/crm/dashboards/kcbreport")
    ),
  },
  {
    key: "hrm-employee-tapdoan",
    path: `${APP_PREFIX_PATH}/hrm/nhan-vien/tap-doan`,
    component: React.lazy(() => import("views/app-views/hrm/employee")),
  },
  {
    key: "hrm-employee-deten",
    path: `${APP_PREFIX_PATH}/hrm/nhan-vien/de-ten`,
    component: React.lazy(() =>
      import("views/app-views/hrm/employee/DSNVDeTen")
    ),
  },
  {
    key: "hrm-employee-thuechungchi",
    path: `${APP_PREFIX_PATH}/hrm/nhan-vien/thue-chung-chi`,
    component: React.lazy(() =>
      import("views/app-views/hrm/employee/DsThueChungChi")
    ),
  },
  {
    key: "hrm-employee-tkhotro",
    path: `${APP_PREFIX_PATH}/hrm/nhan-vien/tai-khoan-ho-tro`,
    component: React.lazy(() =>
      import("views/app-views/hrm/employee/DsTaiKhoanHoTro")
    ),
  },
  {
    key: "hrm-employee-transfer",
    path: `${APP_PREFIX_PATH}/hrm/nhan-vien/dieu-chuyen`,
    component: React.lazy(() =>
      import("views/app-views/hrm/employee/Transfer")
    ),
  },
  {
    key: "hrm-employee-import",
    path: `${APP_PREFIX_PATH}/hrm/nhan-vien/import`,
    component: React.lazy(() => import("views/app-views/hrm/employee/import")),
  },
  {
    key: "cate-department",
    path: `${APP_PREFIX_PATH}/hrm/thiet-lap/co-cau-to-chuc`,
    component: React.lazy(() => import("views/app-views/hrm/department")),
  },
  {
    key: "branch-auth",
    path: `${APP_PREFIX_PATH}/common/phan-quyen/phan-quyen-chi-nhanh`,
    component: React.lazy(() =>
      import("views/app-views/permitRole/userBranch")
    ),
  },
  {
    key: "hrm.bhytcategory",
    path: `${APP_PREFIX_PATH}/hrm/thiet-lap/bhyt-dsnv`,
    component: React.lazy(() =>
      import("views/app-views/hrm/setting/bhxh-dmnv")
    ),
  },
  {
    key: "scope-auth",
    path: `${APP_PREFIX_PATH}/common/phan-quyen/phan-quyen-phan-he`,
    component: React.lazy(() => import("views/app-views/permitRole/scopeAuth")),
  },
  {
    key: "role-manager",
    path: `${APP_PREFIX_PATH}/common/phan-quyen/quan-ly-vai-tro`,
    component: React.lazy(() =>
      import("views/app-views/permitRole/roleManager")
    ),
  },
  {
    key: "permit-employee",
    path: `${APP_PREFIX_PATH}/common/phan-quyen/phan-quyen-nhan-vien`,
    component: React.lazy(() => import("views/app-views/permitRole/userRole")),
  },
  {
    key: "permit-employee-detail",
    path: `${APP_PREFIX_PATH}/common/phan-quyen/chi-tiet-quyen-nhan-vien`,
    component: React.lazy(() =>
      import("views/app-views/permitRole/userRoleDetail")
    ),
  },
  {
    key: "profile",
    path: `${APP_PREFIX_PATH}/hrm/setting-account/*`,
    component: React.lazy(() => import("views/app-views/setting-accont")),
  },
  {
    key: "khachhang-management",
    path: `${APP_PREFIX_PATH}/crm/khach-hang`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/KhachHang")
    ),
  },
  {
    key: "khachhang-ncc-management",
    path: `${APP_PREFIX_PATH}/crm/khach-hang-ncc`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/KhachHangNCC")
    ),
  },
  {
    key: "hospital-management",
    path: `${APP_PREFIX_PATH}/crm/benh-vien`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Hospital")
    ),
  },
  {
    key: "doctor-management",
    path: `${APP_PREFIX_PATH}/crm/bac-sy`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Doctor")
    ),
  },
  {
    key: "partner-list",
    path: `${APP_PREFIX_PATH}/crm/cong-tac-vien/danh-sach`,
    component: React.lazy(() => import("views/app-views/crm/partner/lists")),
  },
  {
    key: "partner-report",
    path: `${APP_PREFIX_PATH}/crm/cong-tac-vien/bao-cao`,
    component: React.lazy(() => import("views/app-views/crm/partner/report")),
  },
  {
    key: "appointment-management",
    path: `${APP_PREFIX_PATH}/crm/dang-ky-kcb`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Appointment")
    ),
  },
  {
    key: "henkhamlai-management",
    path: `${APP_PREFIX_PATH}/crm/hen-kham-lai`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/HenKhamLai")
    ),
  },
  {
    key: "posts-management",
    path: `${APP_PREFIX_PATH}/crm/tin-tuc`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Posts")
    ),
  },
  {
    key: "edit-post",
    path: `${APP_PREFIX_PATH}/crm/tin-tuc/*`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Posts/CreateAndUpdatePosts")
    ),
  },
  {
    key: "setting-banner",
    path: `${APP_PREFIX_PATH}/crm/thiet-lap`,
    component: React.lazy(() => import("views/app-views/crm/setting")),
  },
  {
    key: "notification-management",
    path: `${APP_PREFIX_PATH}/crm/thong-bao`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Notification")
    ),
  },
  {
    key: "edit-notification-management",
    path: `${APP_PREFIX_PATH}/crm/thong-bao/*`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Notification/CreateNotification")
    ),
  },
  {
    key: "ambulance-service-management",
    path: `${APP_PREFIX_PATH}/crm/dich-vu-goi-xe`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Ambulance")
    ),
  },
  {
    key: "crm-dich-vu-tai-nha-management",
    path: `${APP_PREFIX_PATH}/crm/dich-vu-tai-nha`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/DichVuTaiNha")
    ),
  },
  {
    key: "ambulance-service-management",
    path: `${APP_PREFIX_PATH}/crm/dich-vu-goi-xe`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Ambulance")
    ),
  },
  {
    key: "comment-management",
    path: `${APP_PREFIX_PATH}/crm/quan-ly-hoi-dap`,
    component: React.lazy(() =>
      import("views/app-views/crm/managements/Comment")
    ),
  },
  {
    key: "crm-ksk-thiet-lap-kham-suc-khoe-doan",
    path: `${APP_PREFIX_PATH}/crm/ksk/thiet-lap-kham-suc-khoe-doan`,
    component: React.lazy(() => import("views/app-views/crm/ksk/thietlap")),
  },
  {
    key: "crm-ksk-doan-kham",
    path: `${APP_PREFIX_PATH}/crm/ksk/doan-kham`,
    component: React.lazy(() => import("views/app-views/crm/ksk/doankham")),
  },
  {
    key: "crm-ksk-phuong-an-kinh-doanh",
    path: `${APP_PREFIX_PATH}/crm/ksk/phuong-an-kinh-doanh`,
    component: React.lazy(() =>
      import("views/app-views/crm/ksk/phuongankinhdoanh")
    ),
  },
  {
    key: "crm-ksk-don-kham-suc-khoe",
    path: `${APP_PREFIX_PATH}/crm/ksk/don-kham-suc-khoe`,
    component: React.lazy(() => import("views/app-views/crm/ksk/donhang")),
  },
  {
    key: "crm-ksk-thanh-ly-hop-dong",
    path: `${APP_PREFIX_PATH}/crm/ksk/thanh-ly-hop-dong`,
    component: React.lazy(() =>
      import("views/app-views/crm/ksk/thanhlyhopdong")
    ),
  },
  {
    key: "crm-ksk-thu-tien",
    path: `${APP_PREFIX_PATH}/crm/ksk/thu-tien`,
    component: React.lazy(() => import("views/app-views/crm/ksk/thutien")),
  },
  {
    key: "crm-ksk-bao-cao-cong-no",
    path: `${APP_PREFIX_PATH}/crm/ksk/bao-cao-cong-no`,
    component: React.lazy(() => import("views/app-views/crm/ksk/baocaocongno")),
  },
  {
    key: "marketing-campaign-list",
    path: `${APP_PREFIX_PATH}/crm/chien-dich-marketing/danh-sach`,
    component: React.lazy(() => import("views/app-views/crm/campaign/index")),
  },
  {
    key: "marketing-campaign-call-history",
    path: `${APP_PREFIX_PATH}/crm/chien-dich-marketing/danh-sach-cuoc-goi`,
    component: React.lazy(() => import("views/app-views/crm/campaign/callHistory")),
  },
  {
    key: "hr-c1",
    path: `${APP_PREFIX_PATH}/hrm/ho-so-cap-1`,
    component: React.lazy(() => import("views/app-views/hrm/hr-records/c1")),
  },
  {
    key: "hr-c2",
    path: `${APP_PREFIX_PATH}/hrm/ho-so-cap-2`,
    component: React.lazy(() => import("views/app-views/hrm/hr-records/c2")),
  },
  {
    key: "hr-khac",
    path: `${APP_PREFIX_PATH}/hrm/thiet-lap/khac`,
    component: React.lazy(() =>
      import("views/app-views/hrm/setting/thietLapKhac")
    ),
  },
  {
    key: "danh-sach-bang-cong",
    path: `${APP_PREFIX_PATH}/hrm/bao-hiem/danh-sach-bang-cong`,
    component: React.lazy(() =>
      import("views/app-views/hrm/bangcongBaoHiem/Timesheet")
    ),
  },
  {
    key: "chi-tiet-bang-cong",
    path: `${APP_PREFIX_PATH}/hrm/bao-hiem/chi-tiet-bang-cong`,
    component: React.lazy(() =>
      import("views/app-views/hrm/bangcongBaoHiem/TimesheetDetail")
    ),
  },
  {
    key: "bang-cong-theo-nam",
    path: `${APP_PREFIX_PATH}/hrm/bao-hiem/bang-cong-theo-nam`,
    component: React.lazy(() =>
      import("views/app-views/hrm/bangcongBaoHiem/bangCongTheoNam")
    ),
  },
  {
    key: "thiet-lap",
    path: `${APP_PREFIX_PATH}/hrm/bao-hiem/thiet-lap`,
    component: React.lazy(() =>
      import("views/app-views/hrm/bangcongBaoHiem/thietLapBangCong")
    ),
  },
  {
    key: "initiative-idea-management",
    path: `${APP_PREFIX_PATH}/hrm/noi-bo/sang-kien-y-tuong`,
    component: React.lazy(() => import("views/app-views/hrm/IdeaInitiative")),
  },
  {
    key: "ghi-nhan-yeu-cau-management",
    path: `${APP_PREFIX_PATH}/hrm/noi-bo/ghi-nhan-yeu-cau`,
    component: React.lazy(() => import("views/app-views/hrm/ghiNhanYeuCau")),
  },
  {
    key: "khao-sat-khach-hang",
    path: `${APP_PREFIX_PATH}/crm/bao-cao/khao-sat`,
    component: React.lazy(() =>
      import("views/app-views/crm/dashboards/khaoSatKhachHang")
    ),
  },
  // tuyen dung area
  {
    key: "hrm-de-xuat-tuyen-dung",
    path: `${APP_PREFIX_PATH}/hrm/tuyen-dung/de-xuat-tuyen-dung`,
    component: React.lazy(() =>
      import("views/app-views/hrm/tuyendung/dexuattuyendung")
    ),
  },
  {
    key: "hrm-ho-so-ung-vien",
    path: `${APP_PREFIX_PATH}/hrm/tuyen-dung/ho-so-ung-vien`,
    component: React.lazy(() =>
      import("views/app-views/hrm/tuyendung/hosoungvien")
    ),
  },
  {
    key: "hrm-danh-gia-ung-vien",
    path: `${APP_PREFIX_PATH}/hrm/tuyen-dung/danh-gia-ung-vien`,
    component: React.lazy(() =>
      import("views/app-views/hrm/tuyendung/danhgiaungvien")
    ),
  },
  {
    key: "hrm-danh-gia-thu-viec",
    path: `${APP_PREFIX_PATH}/hrm/tuyen-dung/danh-gia-thu-viec`,
    component: React.lazy(() =>
      import("views/app-views/hrm/tuyendung/danhgiathuviec")
    ),
  },
  {
    key: "tuyen-dung-thiet-lap",
    path: `${APP_PREFIX_PATH}/hrm/tuyen-dung/thiet-lap`,
    component: React.lazy(() =>
      import("views/app-views/hrm/tuyendung/thietlap")
    ),
  },
  // end tuyen dung area
  {
    key: "cong-viec-chi-tiet",
    path: `${APP_PREFIX_PATH}/project/cong-viec-chi-tiet`,
    component: React.lazy(() =>
      import("views/app-views/project/CongViec/project-list/QuanLyCongViec")
    ),
  },
  {
    key: "thiet-lap-cham-cong",
    path: `${APP_PREFIX_PATH}/hrm/thiet-lap/cham-cong`,
    component: React.lazy(() => import("views/app-views/hrm/setting/ChamCong")),
  },
  {
    key: "list-contract",
    path: `${APP_PREFIX_PATH}/hrm/hop-dong/danh-sach`,
    component: React.lazy(() =>
      import("views/app-views/hrm/employmentContract/list/EmploymentContract")
    ),
  },
  {
    key: "setting-contract",
    path: `${APP_PREFIX_PATH}/hrm/hop-dong/thiet-lap`,
    component: React.lazy(() =>
      import("views/app-views/hrm/employmentContract/settings")
    ),
  },
  {
    key: "list-training",
    path: `${APP_PREFIX_PATH}/hrm/dao-tao/danh-sach`,
    component: React.lazy(() => import("views/app-views/hrm/training/list")),
  },
  {
    key: "setting-training",
    path: `${APP_PREFIX_PATH}/hrm/dao-tao/thiet-lap`,
    component: React.lazy(() => import("views/app-views/hrm/training/setting")),
  },
  {
    key: "manage-training",
    path: `${APP_PREFIX_PATH}/hrm/dao-tao/quan-ly`,
    component: React.lazy(() => import("views/app-views/hrm/training/manage")),
  },
  {
    key: "manage-overtime",
    path: `${APP_PREFIX_PATH}/hrm/tang-ca/quan-ly`,
    component: React.lazy(() =>
      import("views/app-views/hrm/overtime/OverTime")
    ),
  },
  {
    key: "manage-meal-allowance",
    path: `${APP_PREFIX_PATH}/hrm/cong-com/quan-ly`,
    component: React.lazy(() =>
      import("views/app-views/hrm/mealAllowance/manage/MealAllowance")
    ),
  },
  {
    key: "list-meal-allowance",
    path: `${APP_PREFIX_PATH}/hrm/cong-com/danh-sach`,
    component: React.lazy(() =>
      import("views/app-views/hrm/mealAllowance/list/index")
    ),
  },
  {
    key: "timesheet",
    path: `${APP_PREFIX_PATH}/hrm/quan-ly-cong/bang-cong`,
    component: React.lazy(() =>
      import("views/app-views/hrm/quanLyCong/Timesheet")
    ),
  },
  {
    key: "timesheet-detail",
    path: `${APP_PREFIX_PATH}/hrm/quan-ly-cong/bang-cong/chi-tiet`,
    component: React.lazy(() =>
      import("views/app-views/hrm/quanLyCong/Timesheet/Detail")
    ),
  },
  {
    key: "timesheet-import",
    path: `${APP_PREFIX_PATH}/hrm/quan-ly-cong/bang-cong/import`,
    component: React.lazy(() =>
      import("views/app-views/hrm/quanLyCong/Timesheet/Detail/DuLieuChamCong")
    ),
  },
  {
    key: "don-xac-nhan-cong",
    path: `${APP_PREFIX_PATH}/hrm/quan-ly-cong/don-xac-nhan-cong`,
    component: React.lazy(() =>
      import("views/app-views/hrm/quanLyNghi/DonXacNhanCong")
    ),
  },
  // Luong area
  {
    key: "hrm-salary.salary-table",
    path: `${APP_PREFIX_PATH}/hrm/quan-ly-luong/bang-luong`,
    component: React.lazy(() => import("views/app-views/hrm/quanLyLuong")),
  },
  {
    key: "hrm-salary.phucapgiamtru-table",
    path: `${APP_PREFIX_PATH}/hrm/quan-ly-luong/phu-cap-giam-tru`,
    component: React.lazy(() =>
      import("views/app-views/hrm/quanLyLuong/phucapgiamtru")
    ),
  },
  {
    key: "hrm-salary.phucapgiamtru-detail",
    path: `${APP_PREFIX_PATH}/hrm/quan-ly-luong/bang-phu-cap-giam-tru/chi-tiet`,
    component: React.lazy(() =>
      import("views/app-views/hrm/quanLyLuong/phucapgiamtru/Detail")
    ),
  },
  {
    key: "hrm-salary.salary-setting",
    path: `${APP_PREFIX_PATH}/hrm/quan-ly-luong/thiet-lap`,
    component: React.lazy(() =>
      import("views/app-views/hrm/quanLyLuong/thietlap")
    ),
  },
  {
    key: "hrm-salary.salary-detail",
    path: `${APP_PREFIX_PATH}/hrm/quan-ly-luong/bang-luong-nhan-vien`,
    component: React.lazy(() =>
      import("views/app-views/hrm/quanLyLuong/luongDetail")
    ),
  }, // end Luong area
  {
    key: "don-xin-nghi",
    path: `${APP_PREFIX_PATH}/hrm/quan-ly-nghi/don-xin-nghi`,
    component: React.lazy(() =>
      import("views/app-views/hrm/quanLyNghi/DonXinNghi")
    ),
  },
  {
    key: "nghi-phep-nam",
    path: `${APP_PREFIX_PATH}/hrm/quan-ly-nghi/nghi-phep-nam`,
    component: React.lazy(() =>
      import("views/app-views/hrm/quanLyNghi/NghiPhepNam")
    ),
  },
  {
    key: "signature-process",
    path: `${APP_PREFIX_PATH}/common/quy-trinh-ky/`,
    component: React.lazy(() =>
      import("views/app-views/common/SignatureProcess")
    ),
  },
  {
    key: "sign-position",
    path: `${APP_PREFIX_PATH}/common/vi-tri-ky`,
    component: React.lazy(() => import("views/app-views/common/SignPosition")),
  },
  // Cong van - cong viec area

  {
    key: "project",
    path: `${APP_PREFIX_PATH}/project/vanban/`,
    component: React.lazy(() => import("views/app-views/project/VanBan/Index")),
  },

  {
    key: "apps.project.list",
    path: `${APP_PREFIX_PATH}/project/list`,
    component: React.lazy(() =>
      import("views/app-views/project/CongViec/project-list/ProjectList")
    ),
  },
  {
    key: "apps.project.scrumboard",
    path: `${APP_PREFIX_PATH}/project/scrumboard`,
    component: React.lazy(() =>
      import("views/app-views/project/CongViec/scrumboard")
    ),
  },
  {
    key: "project-thiet-lap",
    path: `${APP_PREFIX_PATH}/project/thiet-lap`,
    component: React.lazy(() =>
      import("views/app-views/project/ThietLap/ThietLap")
    ),
  },
  {
    key: "project-regulation-setting",
    path: `${APP_PREFIX_PATH}/project/quy-che/thiet-lap`,
    component: React.lazy(() =>
      import("views/app-views/project/quyChe/setting")
    ),
  },
  // {
  //   key: "project-regulation-manage",
  //   path: `${APP_PREFIX_PATH}/project/quy-che/quan-ly`,
  //   component: React.lazy(() =>
  //     import("views/app-views/project/quyChe/manage")
  //   ),
  // },
  {
    key: "project-regulation-manage-quyche",
    path: `${APP_PREFIX_PATH}/project/quan-ly/quy-che`,
    component: React.lazy(() =>
      import("views/app-views/project/quyChe/manage/QuyChe")
    ),
  },
  {
    key: "project-regulation-manage-vanban",
    path: `${APP_PREFIX_PATH}/project/quan-ly/van-ban`,
    component: React.lazy(() =>
      import("views/app-views/project/quyChe/manage/VanBan")
    ),
  },
  {
    key: "project-regulation-manage-ho-so",
    path: `${APP_PREFIX_PATH}/project/quan-ly/ho-so`,
    component: React.lazy(() =>
      import("views/app-views/project/quyChe/manage/HoSo")
    ),
  },
  {
    key: "sale",
    path: `${APP_PREFIX_PATH}/sale`,
    component: React.lazy(() => import("views/app-views/sale/dashboard")),
  },
  {
    key: "sale/nhapkho-management",
    path: `${APP_PREFIX_PATH}/sale/quan-ly-kho/nhap-kho`,
    component: React.lazy(() =>
      import("views/app-views/sale/managements/QuanLyKho/NhapKho")
    ),
  },
  {
    key: "sale/xuatkho-management",
    path: `${APP_PREFIX_PATH}/sale/quan-ly-kho/xuat-kho`,
    component: React.lazy(() =>
      import("views/app-views/sale/managements/QuanLyKho/XuatKho")
    ),
  },
  {
    key: "sale/chuyenkho-management",
    path: `${APP_PREFIX_PATH}/sale/quan-ly-kho/chuyen-kho`,
    component: React.lazy(() =>
      import("views/app-views/sale/managements/QuanLyKho/ChuyenKho")
    ),
  },
  {
    key: "sale/banbuon-management",
    path: `${APP_PREFIX_PATH}/sale/quan-ly-ban-hang/ban-buon`,
    component: React.lazy(() =>
      import("views/app-views/sale/managements/BanHang/BanBuon")
    ),
  },
  {
    key: "sale/banle-management",
    path: `${APP_PREFIX_PATH}/sale/quan-ly-ban-hang/ban-le`,
    component: React.lazy(() =>
      import("views/app-views/sale/managements/BanHang/BanLe")
    ),
  },
  {
    key: "sale/trahang-management",
    path: `${APP_PREFIX_PATH}/sale/quan-ly-ban-hang/tra-hang`,
    component: React.lazy(() =>
      import("views/app-views/sale/managements/BanHang/TraHang")
    ),
  },
  {
    key: "sale/dmkk-category",
    path: `${APP_PREFIX_PATH}/sale/danh-muc/dmkk`,
    component: React.lazy(() => import("views/app-views/sale/category/Dmkk")),
  },
  {
    key: "sale/dmdonvitinh-category",
    path: `${APP_PREFIX_PATH}/sale/danh-muc/dmdonvitinh`,
    component: React.lazy(() =>
      import("views/app-views/sale/category/Dmdonvitinh")
    ),
  },
  {
    key: "sale/dmloaidichvu-category",
    path: `${APP_PREFIX_PATH}/sale/danh-muc/dmloaidichvu`,
    component: React.lazy(() =>
      import("views/app-views/sale/category/DmLoaiDichVu")
    ),
  },
  {
    key: "sale/dmhoatchat-category",
    path: `${APP_PREFIX_PATH}/sale/danh-muc/dmhoatchat`,
    component: React.lazy(() =>
      import("views/app-views/sale/category/Dmhoatchat")
    ),
  },
  {
    key: "sale/dmnhomthuoc-category",
    path: `${APP_PREFIX_PATH}/sale/danh-muc/dmnhomthuoc`,
    component: React.lazy(() =>
      import("views/app-views/sale/category/Dmnhomthuoc")
    ),
  },
  {
    key: "sale/dmthuocvattu-category",
    path: `${APP_PREFIX_PATH}/sale/danh-muc/dmthuocvattu`,
    component: React.lazy(() =>
      import("views/app-views/sale/category/Dmthuocvattu")
    ),
  },
  {
    key: "sale/khaibaogiaban-category",
    path: `${APP_PREFIX_PATH}/sale/danh-muc/khaibaogiaban`,
    component: React.lazy(() =>
      import("views/app-views/sale/category/Khaibaogiaban")
    ),
  },
  {
    key: "sale/dmhanghetton-category",
    path: `${APP_PREFIX_PATH}/sale/danh-muc/dmhanghetton`,
    component: React.lazy(() =>
      import("views/app-views/sale/category/Dmhanghetton")
    ),
  },
  {
    key: "sale/tra-cuu-ton",
    path: `${APP_PREFIX_PATH}/sale/tra-cuu-ton`,
    component: React.lazy(() =>
      import("views/app-views/sale/lookup/tracuuton")
    ),
  },
  {
    key: "sale/tra-cuu-cong-no",
    path: `${APP_PREFIX_PATH}/sale/tra-cuu-cong-no`,
    component: React.lazy(() =>
      import("views/app-views/sale/lookup/tracuucongno")
    ),
  },
  {
    key: "sale/thiet-lap-he-thong",
    path: `${APP_PREFIX_PATH}/sale/thiet-lap-he-thong`,
    component: React.lazy(() => import("views/app-views/sale/thietlaphethong")),
  },
  {
    key: "sale/thu-tien-thanh-toan",
    path: `${APP_PREFIX_PATH}/sale/thu-tien`,
    component: React.lazy(() =>
      import("views/app-views/sale/thanhtoan/thutien")
    ),
  },
  {
    key: "sale/chi-tien-thanh-toan",
    path: `${APP_PREFIX_PATH}/sale/chi-tien`,
    component: React.lazy(() =>
      import("views/app-views/sale/thanhtoan/chitien")
    ),
  },
  {
    key: "sale/bang-ke-nhap",
    path: `${APP_PREFIX_PATH}/sale/bang-ke-nhap`,
    component: React.lazy(() =>
      import("views/app-views/sale/baocao/bangkenhap")
    ),
  },
  {
    key: "/sale/bang-ke-xuat",
    path: `${APP_PREFIX_PATH}/sale/bang-ke-xuat`,
    component: React.lazy(() =>
      import("views/app-views/sale/baocao/bangkexuat")
    ),
  },
  {
    key: "sale/bao-cao-nhap-xuat-ton",
    path: `${APP_PREFIX_PATH}/sale/bao-cao-nhap-xuat-ton`,
    component: React.lazy(() =>
      import("views/app-views/sale/baocao/xuatnhapton")
    ),
  },
  {
    key: "sale/bao-cao-doanh-thu",
    path: `${APP_PREFIX_PATH}/sale/bao-cao-doanh-thu`,
    component: React.lazy(() => import("views/app-views/sale/baocao/doanhthu")),
  },
  {
    key: "sale-de-xuat-mua-sam",
    path: `${APP_PREFIX_PATH}/sale/de-xuat-mua-sam`,
    component: React.lazy(() => import("views/app-views/sale/dexuatmuasam")),
  },
  {
    key: "sale-tong-hop-de-xuat-mua-sam",
    path: `${APP_PREFIX_PATH}/sale/tong-hop-de-xuat-mua-sam`,
    component: React.lazy(() =>
      import("views/app-views/sale/tonghopdexuatmuasam")
    ),
  },
  {
    key: "ke-hoach-mua-hang",
    path: `${APP_PREFIX_PATH}/sale/ke-hoach-mua-hang`,
    component: React.lazy(() => import("views/app-views/sale/kehoachmuahang")),
  },
];
